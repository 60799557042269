.container {
  display: flex;
  width: 100%;
  .info {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    white-space: pre-line;
  }
  .picture {
    border-radius: 1rem;
    width: 300px;
    height: 300px;
    background-size: contain;
    background-image: url("./image.jpeg");
  }
}