@import './../../variables.scss';

.label {
  font-size: 10px;
  line-height: 22px;
  font-weight: 600;
  padding: 0px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;

  &.success {
    border-color: $green;
    background-color: $green-lighter;
    color: $green-dark;
  }
  &.danger {
    border-color: $red;
    background-color: $red-lighter;
    color: $red-dark;
  }
  &.warning {
    border-color: $yellow;
    background-color: $yellow-lighter;
    color: $yellow-dark;
  }
  &.info {
    border-color: $primary;
    background-color: $primary-lighter;
    color: $primary-dark;
  }
}