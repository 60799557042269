@import '../../variables.scss';

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  .header {
    position: sticky;
    top: 80px;
    width: 100%;
    min-height: 80px;
    background-color: rgb(249, 249, 249);
    border-radius: 3px;
    .top {
      margin: 8px;
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 32px;
        font-weight: 400;
      }
      .slider {
        flex: 0 0 160px;
        cursor: pointer;
        margin: 8px;
        display: flex;
        align-items: center;
      }
    }
  }
  .projects {
    display: flex;
    padding: 0 8px;
    flex-direction: column;
    .project {
      padding: 16px;
      .top {
        display: flex;
        border-bottom: 1px solid #ececec;
        margin-bottom: 16px;
        .left {
          display: flex;
          flex:1;
          .name {
            font-size: 24px;
            flex: 1;
            display: flex;
            align-items: center;
          }
        }
        .right {
          display: flex;
          button {
            border: 1px solid $main-color;
            color: $main-color;
            margin-left: 8px;
            background-color: aliceblue;
            font-weight: bold;
            border-radius: 3px;
            &:focus, &:active {
              color: $main-color;
              border: 1px solid $main-color;
            }
          }
          .period {
            display: flex;
            align-items: flex-end;
            justify-self: flex-end;
          }
          .previewActions {
            display: flex;
            align-items: center;
            a {
              max-height: 24px;
              margin-left: 4px;
              &:hover > svg > path {
                fill: $main-color;
              }
            }
            .ghIcon {
              padding: 3px;
              width: 18px;
              height: 18px;
            }
          }
        }
      }
      .mid {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .role,
        .employer {
          display: flex;
          line-height: 1.5em;
        }
        .description {
          margin-top: 16px;
        }
        .preview {
          margin-top: 8px;
        }
        .tags {
          margin: 16px 0 8px;
          display: flex;
          width: 100%;
          overflow: hidden;
          flex-wrap: wrap;
          .tag {
            margin-right: 4px;
            margin-top: 4px;
          }
        }
      }
    }
  }
}
.key {
  font-weight: 600;
  margin-right: 8px;
}