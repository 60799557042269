@import '../../variables.scss';

.navigation {
  display: flex;
  border-bottom: #ececec;
  flex: 1;
  align-items: center;
  .item {
    margin-right: 4px; 
    padding: 10px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    .underline {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $main-color;
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
      transition: opacity 300ms, transform 300ms;
    }
    &:hover > .underline, 
    &.active > .underline {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}
