
.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  
  .header,
  .content,
  .footer {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }
  .header {
    flex: 0 0 80px;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0px;
    background: white;
    z-index: 1;
  }
  .footer {
    flex: 0 0 100vh;
  }
}

.section {
  margin: 16px 0;
}