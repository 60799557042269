.container {
  width: 100%;
  padding: 24px 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  .item {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: center;
    flex: 1;
    .icon {
      flex: 0 0 72px;
      width: 72px;
      height: 72px;
      g {
        fill: black;
      }
    }
    .text {
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      font-weight: 500;
      text-align: center;
    }
  }
}