.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .slider {
    width: 100%;    
  }
  .labels {
    display: flex;
    justify-content: space-between;
    font-size: 11px;
    line-height: 11px;
    position: absolute;
    left: 0;
    right: 0;
    top: 34px;
    div:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .info {
    display: flex;
    justify-content: center;
    font-size: 11px;
    line-height: 11px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
}