$main-color: #7051ef;

$primary-darker:   #004060;
$primary-dark:     #00628E;
$primary:          #00A1ED;
$primary-light:    #67C8F5;
$primary-lighter:  #CCECFB;
$primary-lightest: #E5F5FD;

$purple-darker:    #3D1F7A;
$purple-dark:      #6445A0;
$purple:           #9370DB;
$purple-light:     #B093EC;
$purple-lighter:   #DCC8F9;
$purple-lightest:  #F1E7FE;

$red-darker:       #591200;
$red-dark:         #851A00;
$red:              #DE2C00;
$red-light:        #EC8067;
$red-lighter:      #F9D5CD;
$red-lightest:     #FCEEEE;

$yellow-darker:    #664615;
$yellow-dark:      #9A6A22;
$yellow:           #FFAF38;
$yellow-light:     #FFCF88;
$yellow-lighter:   #FFEFD7;
$yellow-lightest:  #FFF7EB;

$green-darker:     #144921;
$green-dark:       #1F6D30;
$green:            #33B650;
$green-light:      #86D396;
$green-lighter:    #D6F0DD;
$green-lightest:   #E7FDED;

$mango-darker:     #A34400;
$mango-dark:       #E05D00;
$mango:            #FF842C;
$mango-light:      #FFC399;
$mango-lighter:    #FFDBC2;
$mango-lightest:   #FFEEDB;

$pink-darker:      #C43831;
$pink-dark:        #ED6962;
$pink:             #FF7F7A;
$pink-light:       #FBBEBC;
$pink-lighter:     #FFD7D6;
$pink-lightest:    #FFF0F0;

$teal-darker:      #065460;
$teal-dark:        #108893;
$teal:             #0DBDB0;
$teal-light:       #7CF4E4;
$teal-lighter:     #C2F5EE;
$teal-lightest:    #EBFFFC;