.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  .header {
    position: sticky;
    top: 80px;
    width: 100%;
    height: 100px;
    background-color: yellowgreen;
  }
}