@import '../../variables.scss';

.container {
  display: flex;
  flex-direction: column;
  .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 24px;
    margin-bottom: 4px;
    button {
      cursor: pointer;
    }
  }
  .frame {
    flex: 1;
    iframe {
      border: 1px solid $main-color;
    }
  }
}