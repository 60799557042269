.container {  
  background: #DEE0E2;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 8px;
  color: #353C49;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.clickable {
    &:hover {
      box-shadow: 0px 3px 4px rgba(53, 60, 73, 0.14);
      cursor: pointer;
    }
  }
  &.active {
    background: #D6F0DD;
    border: 1px solid #33B650;
    color: #144921;
  }
}