.container {
  width: 100%;
  display: flex;
  .side {
    flex: 0 0 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      display: flex;
      flex-direction: column;
      svg {
        width: 128px;
        height: 128px;
      }
      .title {
        color: rgb(132, 132, 132);
        text-align: center;
        margin-top: 4px;
      }
    }
  }
  .items {
    flex: 1;
    display: flex;
    flex-direction: column;
    .item {
      padding: 16px;
      display: flex;
      flex-direction: column;
      border-radius: 1em;
      .head {
        display: flex;
        .left {
          flex: 1;
          display: flex;
          .logo {
            flex: 0 0 48px;
            svg,
            img {
              width: 32px;
              height: 32px;
            }
          }
          .details {
            display: flex;
            flex-direction: column;
            .name {
              font-size: 20px;
              a {
                color: inherit;
                text-decoration: none;
              }
              &:hover {
                a {
                  text-decoration: underline;
                }
              }
            }
            .location {
              color: rgb(132, 132, 132);
              font-size: 12px;
            }
          }
        }
        .right {
          display: flex;
        }
      }
      .body {
        display: flex;
        .indent {
          flex: 0 0 48px;
        }
        .content {
          display: flex;
          flex-direction: column;
          .role {
            font-weight: 600;
            margin-top: 16px;
          }
          .bullets {
            list-style-type: "- ";
            margin-top: 4px;
          }
        }
      }
      .keywords {
        display: flex;
        .keyword {
          margin-right: 4px;
        }
      }
    }
  }
}